import * as React from 'react';
import StatusRow from './StatusRow';
import { SortableContainer } from 'react-sortable-hoc';

const StatusList = SortableContainer(
  ({ desk, statuses, updateStatusField, removeStatus, errorState }) => (
    <ul style={{ padding: 0, listStyle: 'none' }}>
      {statuses?.map((status, index) => (
        <StatusRow
          key={`item-${status._id}`}
          desk={desk}
          index={index}
          status={status}
          statuses={statuses}
          removeStatus={() => removeStatus(index)}
          updateColor={(color) => {
            updateStatusField('color', color, index);
          }}
          updateName={(name) => {
            updateStatusField('name', name, index);
          }}
          updateAbbr={(abbr) => {
            updateStatusField('status', abbr, index);
          }}
          updateType={(type) => {
            updateStatusField('type', type, index);
          }}
          updateCount={(count) => {
            updateStatusField('count', count, index);
          }}
          errorState={
            errorState && (!status.name || !status.color || !status.status || !status.type)
          }
        />
      ))}
    </ul>
  )
);

export default StatusList;
