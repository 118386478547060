const prepareUpdateCallRecord = (mutation, user) => (call) => {
  const id = call?._id;
  delete call?._id;
  delete call?.contactInfo;
  delete call?.contact?._id;
  delete call?.createdAt;
  delete call?.updatedAt;
  delete call?.updatedBy;
  delete call?.deletedBy;
  delete call?.contacts;

  return mutation({
    variables: {
      id,
      email: user.email,
      azureId: user.azure_id,
      input: {
        ...call,
        notes: call?.notes?.map((note) => ({
          ...note,
          createdBy: note?.createdBy?._id ?? note?.createdBy ?? user?._id,
          createdAt: note?.createdAt ?? new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        })),
        recipientId: call?.recipientId?._id,
        createdBy: typeof call?.createdBy === 'string' ? call?.createdBy : call?.createdBy?._id,
      },
    },
  });
};

export default prepareUpdateCallRecord;
