import React, { useState, useRef } from 'react';
import { StatusPill, Icons, Tooltip, colors } from '@united-talent-agency/components';
import {
  BulkStatusUpdateItem,
  StatusList,
  StatusMenuContainer,
  StatusSelectorContainer,
} from './styles';
import useOnBlur from '../../hooks/useOnBlur';
import { CALL_LIST } from '../../support/cypressTags';
import UpdateModal from './Modal';
import { useCallRecordsQuery, useUpdateCallRecordsStatusMutation } from '../../generated/graphql';
import { connect } from 'react-redux';

const Component = ({
  user,
  desk,
  statuses,
  selectAll,
  selectExcept = new Set(),
  filterParams,
  onExit,
  onUpdateApplied = () => {},
}) => {
  const [updateCallRecordsStatus] = useUpdateCallRecordsStatusMutation();
  const { refetch } = useCallRecordsQuery({
    skip: true,
  });

  // Refs
  const wrapperRef = useRef(null);

  // States
  const [showBulkStatusUpdateList, setShowBulkStatusUpdateList] = useState(false);
  const [statusToUpdate, setStatusToUpdate] = useState();
  const [bulkUpdateImpacted, setBulkUpdateImpacted] = useState(0);

  const someSelected = selectAll || selectExcept.size > 0;

  useOnBlur(wrapperRef, () => {
    setShowBulkStatusUpdateList(false);
  });

  const submitBulkUpdate = async () => {
    try {
      setShowBulkStatusUpdateList(false);

      if (!bulkUpdateImpacted) {
        setStatusToUpdate(undefined);
        return;
      }

      const update = {
        filterParams,
        all: selectAll,
        except: Array.from(selectExcept),
        status: statusToUpdate,
      };

      const status = statuses.find((s) => s.status === statusToUpdate);

      if (['Initiate', 'Close'].includes(status.type)) {
        update.occurrence_date = new Date().getTime();
      }

      if (update.all) {
        await updateCallRecordsStatus({
          variables: {
            azureId: user?.azure_id,
            email: user?.email,
            input: [
              {
                except: update.except,
                current: {
                  deskId: desk._id,
                  status: update.status,
                },
              },
            ],
          },
        });
      } else {
        await updateCallRecordsStatus({
          variables: {
            azureId: user?.azure_id,
            email: user?.email,
            input: [
              {
                ids: update.except,
                current: {
                  deskId: desk._id,
                  status: update.status,
                },
              },
            ],
          },
        });
      }

      setBulkUpdateImpacted(0);
      setStatusToUpdate(undefined);
      onUpdateApplied();
    } catch (err) {
      setStatusToUpdate(undefined);
      console.error(err);
      onExit && onExit();
    }
  };

  /** Handles bulk delete side effects
   *
   * @param {function} _getCommunications: Gets a list of call_todos based on query params. Dependency injected for testing.
   * @returns {void}
   **/
  const handleBulkUpdate = (status) => {
    setShowBulkStatusUpdateList(false);

    const totalImpacted = !selectAll
      ? Promise.resolve(selectExcept.size)
      : refetch({
          email: user?.email,
          azureId: user?.azure_id,
          where: {
            deskId: filterParams?.deskId,
            search: filterParams?.query,
            statuses: filterParams?.filter,
          },
          order: {
            statusThenOccurrence: true,
          },
          skip: 0,
          take: 1,
        })
          .then(({ data }) => data?.callRecords?.meta?.total ?? 0)
          .then((totalCount) => totalCount - selectExcept.size);

    totalImpacted.then((impactedCount) => {
      setBulkUpdateImpacted(impactedCount);
      setStatusToUpdate(status);
    });
  };

  return (
    <div>
      <StatusSelectorContainer
        ref={wrapperRef}
        data-cy={CALL_LIST.BULK_UPDATE_STATUS}
        style={{
          cursor: `${someSelected ? 'pointer' : 'default'}`,
        }}
      >
        <StatusMenuContainer
          style={!someSelected ? { color: colors.disabledGrey } : {}}
          onClick={() => {
            setShowBulkStatusUpdateList((oldState) => !oldState);
          }}
        >
          <Tooltip
            text={!someSelected ? 'Select rows to update status on' : 'Set status on selected rows'}
            place="top"
          >
            <div style={{ paddingBottom: '3px' }}>
              Status <Icons.ChevronDown color={!someSelected ? colors.disabledGrey : undefined} />
            </div>
          </Tooltip>
        </StatusMenuContainer>
        {someSelected && showBulkStatusUpdateList && (
          <StatusList>
            {statuses
              .filter((status) => status.status)
              .map((status) => (
                <BulkStatusUpdateItem
                  key={status._id}
                  onClick={() => handleBulkUpdate(status.status)}
                >
                  <StatusPill status={status.status} color={status.color} />
                </BulkStatusUpdateItem>
              ))}
          </StatusList>
        )}
        {!!statusToUpdate && (
          <UpdateModal
            isOpen
            newStatus={statusToUpdate}
            impactedCount={bulkUpdateImpacted}
            onCancel={() => {
              setBulkUpdateImpacted(0);
              onExit && onExit();
              setStatusToUpdate(undefined);
            }}
            onConfirm={submitBulkUpdate}
          />
        )}
      </StatusSelectorContainer>
    </div>
  );
};

const withState = connect(({ user }) => {
  return { user };
});

const BulkUpdateStatus = withState(Component);

export default BulkUpdateStatus;
