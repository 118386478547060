import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';

import { Branding, Button, Icons } from '@united-talent-agency/components';
import { requestPromise } from '@united-talent-agency/julius-frontend-store';
import CallForm from '../CallForm';
import Filters from './Filters';
import Header from '../../shared/Header';
import {
  createShortcuts,
  filterInputEvent,
  keyboard,
  stringifyKey,
} from '../../../support/keyboard';
import { createCapture } from '../../../support/telemetry';
import { getNotes } from '../../../api/people';
import { CALL_FORM, FILTERS, NAV_BAR } from '../../../support/cypressTags';

import phoneSheetSymbol from '../../../assets/logo/phone_sheet_symbol_200x200.png';
import { saveCall } from '../../../data/call-todo';
import { apiServerUrl } from '../../../support/urls';
import DuplicateCallModal from '../../../components/DuplicateCallModal';
import { notify } from 'react-notify-toast';
import {
  GridContainer,
  BrandingGridCell,
  SearchBarPlaceholderGridCell,
  SearchBarGridCell,
  ButtonContainerGridCell,
  ButtonContainer,
  FilterContainer,
  ResultCountContainer,
  PillsContainer,
  ResetButton,
  Pills,
  AddCall,
  CallFormGridCell,
  FilterSelectorGridCell,
  CallListGridCell,
} from './styles';
import { useDebouncedCallback } from 'use-debounce';
import FilterPills from '../../../components/FilterPills';
const { ChevronRightIcon } = Icons;
import { styled } from 'react-free-style';
import { useCreateCallRecordMutation } from '../../../generated/graphql';
import { useUpdateCallRecordMutation } from '../../../generated/graphql';
import prepareUpdateCallRecord from '../../../utils/prepare-update-call-record';

const SHOW_BANNER = process.env.REACT_APP_SHOW_BANNER;

const Component = ({
  desk,
  user,
  search,
  styles,
  setDesk,
  children,
  dispatch,
  navigate,
  fetchCounts,
  fullRefresh,
  resetSearch,
  totalCounts,
  updateQuery,
  deskStatuses,
  onChangeDesk,
  setSelectAll,
  filtersExpanded,
  refreshDeskData,
  setSelectExcept,
  changeFiltersExpanded,
}) => {
  const [createCallRecordMutation] = useCreateCallRecordMutation();
  const [updateCallRecordMutation] = useUpdateCallRecordMutation();

  // Refs
  const lastCollapseCallFormData = useRef(null);

  // States
  const [openCallForm, setOpenCallForm] = useState(false);
  const [callTodoFormData, setCallTodoFormData] = useState(null);
  const [pendingCallTodo, setPendingCallTodo] = useState([]);
  const [dupeId, setDupeId] = useState();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const createCallRecord = (call) => {
    delete call?.contact?._id;
    delete call?.contactInfo;

    return createCallRecordMutation({
      variables: {
        input: {
          ...call,
          notes: call?.notes?.map((note) => ({
            ...note,
            createdBy: user._id,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
          })),
          recipientId: call?.recipientId?._id,
          createdBy: user._id,
          newContact: true,
        },
      },
    });
  };

  const updateCallRecord = prepareUpdateCallRecord(updateCallRecordMutation, user);

  const resetCallForm = ({ occurrence_date } = {}) => {
    const status = (deskStatuses?.[0] || {}).status;
    const recipientName = '';
    const description = '';
    const contact = { contactType: 'Office Phone' };

    setCallTodoFormData({
      contact,
      status,
      recipientName,
      description,
      occurrence_date,
    });
  };

  const resetByCollapsableState = (close) => {
    if (desk?.settings?.collapseCallForm) {
      resetCallForm({ occurrence_date: new Date().getTime() });
      close && setOpenCallForm(false);
    } else {
      resetCallForm();
      const personInput = document.getElementById('person-input-name');
      if (personInput) {
        personInput.focus();
      }
    }
  };

  useEffect(() => {
    // to avoid unecessary rerenders
    if (lastCollapseCallFormData.current === desk?.settings?.collapseCallForm) {
      return;
    }

    lastCollapseCallFormData.current = desk?.settings?.collapseCallForm;

    if (desk?.settings?.collapseCallForm) {
      resetCallForm({ occurrence_date: new Date().getTime() });
      setOpenCallForm(false);
    } else if (!callTodoFormData) {
      resetCallForm();
      const personInput = document.getElementById('person-input-name');
      if (personInput) {
        personInput.focus();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callTodoFormData, desk?.settings?.collapseCallForm]);

  useEffect(() => {
    setCallTodoFormData((oldState) => ({
      ...oldState,
      status: (deskStatuses?.[0] || {}).status,
    }));
  }, [deskStatuses]);

  useEffect(() => {
    const keyboardListener = filterInputEvent(
      createShortcuts({
        [stringifyKey('n')]: (e) => {
          e.preventDefault();
          resetByCollapsableState();
        },
      })
    );

    keyboard.addListener(keyboardListener);

    return () => {
      keyboard.removeListener(keyboardListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Handles side-effects when the desk is changed.
   *
   * Closes the call form, if it is closeable
   * Calls the function to set a new desk
   * Fetches statuses for the new desk and stores them in local state
   *
   * @param {Object} desk - The new desk.
   * @returns {void}
   */
  const handleChangeDesk = useDebouncedCallback(
    async (desk) => {
      if (desk?.settings?.collapseCallForm) {
        // nullifying the callTodo in local state closes the call form
        setCallTodoFormData(null);
      }
      onChangeDesk && onChangeDesk(desk);
    },
    [setDesk]
  );

  const sumOfFetchCounts = useMemo(
    () => Object.keys(fetchCounts).reduce((prev, cur) => (prev += fetchCounts[cur]), 0),
    [fetchCounts]
  );

  const sumOfTotalCounts = useMemo(
    () => Object.keys(totalCounts).reduce((prev, cur) => (prev += totalCounts[cur]), 0),
    [totalCounts]
  );

  const handleResetButtonClick = useCallback(() => {
    setSelectAll(false);
    setSelectExcept(new Set());
    resetSearch && resetSearch();
  }, [resetSearch, setSelectAll, setSelectExcept]);

  const handleOnCallAdd = useCallback(
    (person) => {
      person.archiveId = person._id;
      person.type = 'Industry Contact';
      person.archive = true;
      delete person._id;

      const phoneContact =
        person.contacts &&
        person.contacts.find((c) => {
          return /Phone/.test(c.contactType);
        });
      const firstContact = phoneContact || (person.contacts && person.contacts[0]);
      const contact = firstContact || { contactType: 'Office Phone' };

      setCallTodoFormData({
        status: deskStatuses[0].status,
        recipientId: person,
        contact,
        contactInfo: contact.contact,
        description: contact.description,
        recipientName: person.name,
        occurrence_date: new Date().getTime(),
      });
    },
    [deskStatuses]
  );

  const handleOnClickCallAddCancel = () => {
    setOpenCallForm((oldState) => !oldState);
    resetByCollapsableState();
  };

  const handleOnSaveCallForm = (data) => {
    data.deskId = desk?._id;

    const dupeCheck = data?.recipientId?._id
      ? requestPromise(user, apiServerUrl, '/call_todo', 'GET', null, [
          { recipientId: data?.recipientId?._id },
          { deskId: data.deskId },
        ])
      : Promise.resolve({});

    //check for dupe
    return dupeCheck.then((response) => {
      if (response.data && response.data.length > 0) {
        setDupeId(response.data[0]._id);
        setPendingCallTodo(data);
      } else {
        return saveCall(data, updateCallRecord, createCallRecord)
          .then(() => {
            notify.show('Call record created', 'success');
            fullRefresh();
            // TODO: Tracking
            resetByCollapsableState(true);
          })
          .catch((error) => {
            notify.show(error?.message || 'Failed to save call.', 'error');
          });
      }
    });
  };

  const handleOnCloseForm = () => {
    resetByCollapsableState(true);
  };

  const handleOnCancelDuplicateModal = useCallback(() => {
    setDupeId(null);
    setPendingCallTodo(null);
  }, []);

  const handleOnCreateNew = () =>
    saveCall(pendingCallTodo, updateCallRecord, createCallRecord).then(() => {
      setDupeId(null);
      setPendingCallTodo(null);
      resetByCollapsableState(true);
      fullRefresh();
    });

  const handleOnUseExisting = async () => {
    pendingCallTodo._id = dupeId;

    try {
      const { body, status } = await getNotes(pendingCallTodo.recipientId._id, desk?._id);

      if (status !== 200) {
        throw new Error('Failed to fetch previous notes.');
      }

      pendingCallTodo.notes = [...pendingCallTodo.notes, ...body.notes];

      const result = await saveCall(pendingCallTodo, updateCallRecord, createCallRecord);

      setDupeId(null);
      setPendingCallTodo(null);
      resetByCollapsableState(true);
      fullRefresh();

      return result;
    } catch (error) {
      notify.show(error?.message || 'Failed to save call.', 'error');
    }
  };

  return (
    <>
      <GridContainer isCollapsed={filtersExpanded}>
        {/* DESK SELECTOR & BANNER */}
        <BrandingGridCell data-cy={NAV_BAR}>
          <Branding
            showDeskSelector
            text="Phonesheet"
            desks={user?.deskIds}
            symbol={phoneSheetSymbol}
            showBanner={SHOW_BANNER}
            currentDeskId={desk?._id}
            setDesk={handleChangeDesk}
          />
        </BrandingGridCell>
        {/* SEARCH BAR AND HAMBURGER MENU */}
        <SearchBarPlaceholderGridCell />
        <SearchBarGridCell>
          <Header
            desk={desk}
            search={search}
            deskStatuses={deskStatuses}
            onCallAdd={handleOnCallAdd}
            updateQuery={updateQuery}
            refreshDeskData={refreshDeskData}
          />
        </SearchBarGridCell>

        {/* ADD CALL & RESET BUTTONS */}
        <ButtonContainerGridCell>
          <ButtonContainer>
            <FilterContainer>
              <ResultCountContainer>
                {sumOfTotalCounts} Calls Total |{' '}
                {sumOfTotalCounts > sumOfFetchCounts ? sumOfFetchCounts : sumOfTotalCounts} Calls
                Displayed
              </ResultCountContainer>
              <PillsContainer>
                {!filtersExpanded && (
                  <ChevronRightIcon
                    onClick={() => changeFiltersExpanded(true)}
                    style={{
                      cursor: 'pointer',
                      fontSize: 12,
                      fontWeight: 700,
                      color: '#000',
                      marginRight: 10,
                    }}
                  />
                )}
                <ResetButton data-cy={FILTERS.RESET} onClick={handleResetButtonClick}>
                  Reset
                </ResetButton>
                <Pills>
                  <FilterPills search={search} navigate={navigate} statuses={deskStatuses} />
                </Pills>
              </PillsContainer>
            </FilterContainer>

            {!!desk?.settings?.collapseCallForm && (
              <AddCall data-cy={CALL_FORM.ADD_TODO_BUTTON}>
                <Button
                  tabIndex={0}
                  className={styles.addCallButton}
                  onClick={handleOnClickCallAddCancel}
                  text={openCallForm ? 'Cancel' : '+ Add Call'}
                  primary={false}
                />
              </AddCall>
            )}
          </ButtonContainer>
        </ButtonContainerGridCell>

        {/* CALL FORM */}
        <CallFormGridCell>
          <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            {(!desk?.settings?.collapseCallForm || openCallForm) && (
              <CallForm
                statuses={deskStatuses || []}
                onSave={handleOnSaveCallForm}
                callTodo={callTodoFormData ?? {}}
                onChange={setCallTodoFormData}
                onClose={handleOnCloseForm}
              />
            )}
          </div>
        </CallFormGridCell>

        {/* FILTERS */}
        <FilterSelectorGridCell>
          <Filters
            search={search}
            navigate={navigate}
            statuses={deskStatuses || []}
            filtersExpanded={filtersExpanded}
            changeFiltersExpanded={changeFiltersExpanded}
            capture={createCapture(dispatch, user._id)}
          />
        </FilterSelectorGridCell>

        {/* CALL LIST */}
        <CallListGridCell>{children}</CallListGridCell>
      </GridContainer>

      <DuplicateCallModal
        isOpen={!!dupeId}
        onCancel={handleOnCancelDuplicateModal}
        onCreateNew={handleOnCreateNew}
        onUseExisting={handleOnUseExisting}
      />
    </>
  );
};

const withStyles = styled({
  addCallButton: {
    '&:focus': {
      borderColor: 'rgb(33, 135, 185) !important',
      outline: '1px solid rgb(33, 135, 185) !important',
    },
  },
});

const withState = connect(({ user }) => {
  return { user };
});

const Wrapper = withState(memo(withStyles(Component)));

export default Wrapper;
