import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { styled } from 'react-free-style';
import { connect } from 'react-redux';
import * as elements from '../../styles/elements';

import { Spinner } from '@united-talent-agency/components';

import Wrapper from './Wrapper';
import CallListWrapper from './CallListWrapper';

import { datadogRum } from '@datadog/browser-rum';
import { useDebouncedCallback } from 'use-debounce';
import { notify } from 'react-notify-toast';
import {
  fullRefresh as handleFullRefreshResult,
  loadMore as handleLoadMoreResult,
} from './CallListWrapper/Helper';
import useQueryParamsSync from '../../hooks/useQueryParamsSync';
import { useCallRecordsQuery } from '../../generated/graphql';

const WIDTH_LIMIT = 1240;
const REFRESH_RATE = (Number(process.env.REACT_APP_REFRESH_RATE_SECONDS) || 10) * 1000;
const DEFAULT_CALL_FETCH_LIMIT = 20;

const Component = ({ user, styles }) => {
  // Refs
  const prevWidth = useRef(WIDTH_LIMIT + 1);
  const latestRefreshTimeRef = useRef();

  // States
  const [fetchCounts, setFetchCounts] = useState({});
  const [totalCounts, setTotalCounts] = useState({});
  const [filtersExpanded, setFiltersExpanded] = useState(true);
  const [selectAll, setSelectAll] = useState(false);
  const [selectExcept, setSelectExcept] = useState(new Set());
  const [callsToDo, setCallsToDo] = useState([]);
  const [statusOfLoadingMore, setStatusOfLoadingMore] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [dataDogSet, setDataDogSet] = useState(false); //  Flag used to register what User is active in the application for DataDog.

  // Sometimes it comes like a desk object and not just only ids. So, lets change it to be an array of ids.
  const deskIds = useMemo(
    () => user?.deskIds?.map((deskId) => deskId?._id?.toString() ?? deskId?.toString()),
    [user?.deskIds]
  );

  const { desk, search, deskStatuses, updateQuery, updateParams, changeDesk, refreshStore } =
    useQueryParamsSync({
      userId: user?._id,
      deskIds,
      initialPhoneSheetFilters: user?.phoneSheetFilter,
    });

  const { refetch: refetchCallRecords } = useCallRecordsQuery({
    skip: true,
  });

  useEffect(() => {
    // if no configuration is active, DD should be set to true
    if (!datadogRum.getInitConfiguration()) {
      setDataDogSet(true);
    }
  }, []);

  useEffect(() => {
    const windowResizeListener = () => {
      // While resizing, if screen size got smaller than mediumBreakpoint, filters should be collapsed
      if (prevWidth.current > WIDTH_LIMIT && window.innerWidth <= WIDTH_LIMIT) {
        setFiltersExpanded(false);
      }
      prevWidth.current = window.innerWidth;
    };

    windowResizeListener();

    window.addEventListener('resize', windowResizeListener);

    return () => {
      window.removeEventListener('resize', windowResizeListener);
    };
  }, []);

  useEffect(() => {
    // Flag allows this to fire 1x per user-login, as desired.
    if (user && user.azure_id && !dataDogSet) {
      datadogRum.setUser({
        id: user.azure_id,
        name: `${user.last_name}, ${user.first_name}`,
        email: user.email,
      });

      datadogRum.startSessionReplayRecording();
      setDataDogSet(true);
    }
  }, [dataDogSet, user]);

  const fullRefresh = useCallback(
    (_search = search) => {
      if (!user?.email || !user?.azure_id || !_search?.deskId || !deskStatuses) {
        return;
      }

      const currentTime = new Date().getTime();
      latestRefreshTimeRef.current = currentTime;

      const deskStatuesesNames = deskStatuses?.map((s) => s.status);
      let statuses = deskStatuesesNames ?? [];
      if (_search?.filter?.length) {
        statuses = deskStatuesesNames.filter((status) => _search?.filter.includes(status));
      }

      const promises = statuses
        ?.filter((_status) => _status !== '')
        ?.map((item) => {
          let limit = DEFAULT_CALL_FETCH_LIMIT;
          if (!!fetchCounts[item] && DEFAULT_CALL_FETCH_LIMIT < fetchCounts[item]) {
            limit = fetchCounts[item];
          }

          return refetchCallRecords({
            email: user?.email,
            azureId: user?.azure_id,
            where: {
              deskId: _search?.deskId,
              search: _search?.query,
              statuses: [item],
              favorite: _search?.favorite,
            },
            order: {
              statusThenOccurrence: true,
            },
            skip: 0,
            take: limit,
          });
        });

      return Promise.all(promises)
        .then((results) => {
          // It invalidates the results if a newer request is made.
          if (currentTime !== latestRefreshTimeRef.current) {
            return;
          }

          isLoading && setIsLoading(false);
          const parsedResults = results.map((result) => ({
            data: result?.data?.callRecords?.callRecords?.filter(
              (callRecord) =>
                !desk?.settings?.hideFutureCalls ||
                callRecord?.occurrence_date <= new Date().getTime()
            ),
            meta: result?.data?.callRecords?.meta,
          }));

          return handleFullRefreshResult(
            parsedResults,
            deskStatuesesNames,
            setFetchCounts,
            setTotalCounts,
            setCallsToDo
          );
        })
        .catch(() => {
          // It invalidates the results if a newer request is made.
          if (currentTime !== latestRefreshTimeRef.current) {
            return;
          }

          isLoading && setIsLoading(false);
          notify.show('Failed to load calls', 'error');
        });
    },
    [
      desk?.settings?.hideFutureCalls,
      deskStatuses,
      fetchCounts,
      isLoading,
      refetchCallRecords,
      search,
      user?.azure_id,
      user?.email,
    ]
  );

  const debouncedFullRefresh = useDebouncedCallback(fullRefresh, 500, {
    leading: false,
    trailing: true,
    maxWait: REFRESH_RATE,
  });

  const resetSearch = useCallback(() => {
    const newData = { filter: undefined, favorite: undefined, query: undefined };
    updateParams(newData);
    fullRefresh({
      ...search,
      ...newData,
    });
  }, [fullRefresh, search, updateParams]);

  const handleOnChangeDesk = useCallback(
    async (selectedDesk) => {
      changeDesk(selectedDesk?._id);
    },
    [changeDesk]
  );

  const navigate = (_search) => {
    updateParams(_search);
    fullRefresh({
      ...search,
      ..._search,
    });
  };

  const handleUpdateQuery = (query) => {
    if (query === search.query) {
      return;
    }

    updateQuery(query);
    fullRefresh({
      ...search,
      query,
    });
  };

  /**
   * Load more calls per status
   *
   * @param {Search} search: params for search
   * @param {string} status: status of calls to load
   **/
  const loadMore = useCallback(
    (_status) => {
      setStatusOfLoadingMore(_status);
      refetchCallRecords({
        email: user.email,
        azureId: user.azure_id,
        where: {
          deskId: search?.deskId,
          statuses: [_status],
          search: search?.query,
          favorite: search?.favorite,
        },
        order: {
          statusThenOccurrence: true,
        },
        skip: fetchCounts[_status],
      })
        .then((result) => {
          const parsedResult = {
            data: result?.data?.callRecords?.callRecords.filter(
              (callRecord) =>
                !desk?.settings?.hideFutureCalls ||
                callRecord?.occurrence_date <= new Date().getTime()
            ),
            meta: result?.data?.callRecords?.meta,
          };
          handleLoadMoreResult(
            parsedResult,
            _status,
            fetchCounts,
            setFetchCounts,
            callsToDo,
            setCallsToDo
          );
          setStatusOfLoadingMore(null);
        })
        .catch(() => {
          setStatusOfLoadingMore(null);
          notify.show('Failed to load more calls', 'error');
        });
    },
    [
      callsToDo,
      desk?.settings?.hideFutureCalls,
      fetchCounts,
      refetchCallRecords,
      search,
      user.azure_id,
      user.email,
    ]
  );

  useEffect(() => {
    // either changing desk and persisting filter call refreshCalls
    // to prevent consequent refreshCalls, use debounce here
    debouncedFullRefresh();
    const subscription = setInterval(() => {
      debouncedFullRefresh();
    }, REFRESH_RATE);

    return () => {
      clearInterval(subscription);
    };
  }, [debouncedFullRefresh, deskStatuses, user?.email, user?.azure_id, search?.deskId]);

  if (isLoading) {
    return (
      <div className={styles.spinner}>
        <Spinner size={60} />
      </div>
    );
  }

  if (!user?.deskIds?.length) {
    return (
      <h5 className="m-4">
        No current office groups configured. Please contact the Service Desk at x3900
      </h5>
    );
  }

  if (!search?.deskId) {
    return <></>;
  }

  return (
    <Wrapper
      desk={desk}
      search={search}
      fetchCounts={fetchCounts}
      totalCounts={totalCounts}
      fullRefresh={debouncedFullRefresh}
      deskStatuses={deskStatuses}
      filtersExpanded={filtersExpanded}
      navigate={navigate}
      resetSearch={resetSearch}
      updateQuery={handleUpdateQuery}
      onChangeDesk={handleOnChangeDesk}
      setSelectAll={setSelectAll}
      setSelectExcept={setSelectExcept}
      refreshDeskData={refreshStore}
      changeFiltersExpanded={setFiltersExpanded}
    >
      <CallListWrapper
        desk={desk}
        search={search}
        callsToDo={callsToDo}
        selectAll={selectAll}
        fetchCounts={fetchCounts}
        totalCounts={totalCounts}
        fullRefresh={debouncedFullRefresh}
        deskStatuses={deskStatuses}
        selectExcept={selectExcept}
        statusOfLoadingMore={statusOfLoadingMore}
        loadMore={loadMore}
        setSelectAll={setSelectAll}
        setTotalCounts={setTotalCounts}
        setSelectExcept={setSelectExcept}
      />
    </Wrapper>
  );
};

const withStyles = styled({
  spinner: {
    display: 'flex',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
  },
  link: elements.link,
});

const withState = connect(({ user }) => {
  // This is stored here because it must be set right after logging in.
  localStorage.setItem('uta.azure_id', user.azure_id);
  localStorage.setItem('uta.user_email', user.email);
  localStorage.setItem('uta.outlook_permissions', user.outlook_permissions);

  return { user };
});

const Home = withStyles(withState(Component));

export default Home;
