import { useCallback, useEffect, useMemo, useState } from 'react';
import { isEqual } from 'lodash';
import { useUpdateUserPhoneSheetFiltersMutation } from '../generated/graphql';
import { useDeskQuery } from '../generated/graphql';
import { useDeskStatusesQuery } from '../generated/graphql';
import { useUserPhoneSheetFilterQuery } from '../generated/graphql';

const numericProperties = ['favorite'];
const arrayProperties = ['filter'];

const objectToQueryString = (params, parseArrays) => {
  if (params) {
    Object.entries(params).forEach(([key, value]) => {
      if (parseArrays && arrayProperties?.includes(key) && Array.isArray(value)) {
        params[key] = value?.join(',');
      }

      if (numericProperties?.includes(key)) {
        if (isNaN(params[key])) {
          delete params[key];
        } else {
          params[key] = String(value);
        }
      }
    });
  }

  const sortedParams = Object.keys(params)
    .sort()
    .reduce((obj, key) => {
      obj[key] = params[key];
      return obj;
    }, {});

  return new URLSearchParams(sortedParams).toString();
};

const queryStringToObject = (queryString, parseArrays) => {
  const obj = Object.fromEntries(new URLSearchParams(queryString));

  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (parseArrays && arrayProperties?.includes(key)) {
      return {
        ...acc,
        [key]: value?.split(/%2C|,/g),
      };
    }

    if (numericProperties?.includes(key)) {
      value = typeof value === 'string' ? Number(value) : value;
      if (isNaN(value)) {
        value = undefined;
      }

      return {
        ...acc,
        [key]: value,
      };
    }

    return {
      ...acc,
      [key]: value,
    };
  }, {});
};

const removeEmpties = (obj) => {
  Object.entries(obj).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value = value.filter(
        (item) =>
          !!item?.length &&
          ![null, undefined, '', 'null', 'undefined'].includes(item) &&
          (typeof value !== 'number' || !isNaN(value))
      );
      obj[key] = value;
    }

    if (
      [null, undefined, '', 'null', 'undefined'].includes(value) ||
      (Array.isArray(value) && !value?.length) ||
      (typeof value === 'number' && isNaN(value))
    ) {
      delete obj[key];
    }
  });

  return obj;
};

export default function useQueryParamsSync({ userId, deskIds, initialPhoneSheetFilters }) {
  // Queries
  const { refetch: refetchDesk } = useDeskQuery({
    skip: true,
  });
  const { refetch: refetchUserFilters } = useUserPhoneSheetFilterQuery({
    skip: true,
  });
  const { refetch: refetchDeskStatuses } = useDeskStatusesQuery({
    skip: true,
  });

  // Mutations
  const [updatePhoneSheetFilters] = useUpdateUserPhoneSheetFiltersMutation();

  // States
  const [desk, setDesk] = useState();
  const [query, setQuery] = useState();
  const [deskId, setDeskId] = useState();
  const [filters, setFilters] = useState([]);
  const [favorite, setFavorite] = useState();
  const [desksFilters, setDesksFilters] = useState(initialPhoneSheetFilters);
  const [deskStatuses, setDeskStatuses] = useState();

  const fetchDesk = useCallback(
    (deskId) =>
      refetchDesk({ id: deskId }).then((response) => {
        const notReadonlyDesk = JSON.parse(JSON.stringify(response?.data?.desks?.[0]));
        setDesk(notReadonlyDesk);
      }),
    [refetchDesk]
  );

  const refetchStatuses = useCallback(
    (deskId) => {
      refetchDeskStatuses({
        id: deskId,
      }).then(({ data }) => {
        const notReadonlyStatuses = JSON.parse(JSON.stringify(data?.desks?.[0]?.statuses));
        setDeskStatuses(notReadonlyStatuses);
      });
    },
    [refetchDeskStatuses]
  );

  useEffect(() => {
    if (!deskFilters) {
      setDesksFilters(initialPhoneSheetFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialPhoneSheetFilters]);

  useEffect(() => {
    if (!deskId && deskIds?.length) {
      const searchParams = new URLSearchParams(window.location.search);
      const searchDeskId = searchParams?.get('deskId');

      const firstDeskId = deskIds?.[0];
      const foundDeskId = deskIds?.find((_deskId) => _deskId === searchDeskId);

      if (!foundDeskId) {
        window.history.replaceState(undefined, '', '/');
      }

      const newDeskId = foundDeskId || firstDeskId;

      if (newDeskId) {
        setDeskId(newDeskId);
      }
    }
  }, [deskId, deskIds]);

  const refreshStore = useCallback(() => {
    fetchDesk(deskId);
    refetchStatuses(deskId);
    refetchUserFilters({
      id: userId,
    }).then((response) => {
      const phoFilterResponse = response?.data?.users?.[0]?.phoneSheetFilter;
      const notReadonlyPhoFilter = JSON.parse(JSON.stringify(phoFilterResponse));
      setDesksFilters(notReadonlyPhoFilter);
    });
  }, [deskId, fetchDesk, refetchStatuses, refetchUserFilters, userId]);

  useEffect(() => {
    if (deskId) {
      refreshStore();
    }
  }, [deskId, refreshStore]);

  const search = useMemo(() => {
    const obj = {
      query,
      deskId,
      favorite,
      filter: filters,
    };

    return removeEmpties(obj);
  }, [deskId, favorite, filters, query]);

  const deskFilters = useMemo(() => {
    const foundDeskFilters = desksFilters?.find((deskFilters) => deskFilters.deskId === deskId);
    if (foundDeskFilters) {
      return removeEmpties(queryStringToObject(objectToQueryString(foundDeskFilters, true), true));
    }

    return undefined;
  }, [deskId, desksFilters]);

  // Sync URL and stored data
  useEffect(() => {
    if (!deskFilters) {
      return;
    }

    const searchObj = removeEmpties(queryStringToObject(window.location.search, true));

    if (!window.location?.search?.length) {
      // If there is no parameters in the URL, or changed the desk, load the stored data and update URL
      deskFilters.deskId = deskFilters?.deskId ?? deskId;
      setFilters(deskFilters?.filter);
      setDeskId(deskFilters?.deskId);
      setFavorite(deskFilters?.favorite);
      setQuery(deskFilters?.query);

      window.history.replaceState(deskFilters, '', `?${objectToQueryString(deskFilters)}`);
    } else if (!isEqual(searchObj, deskFilters)) {
      // In case of different params in the URL, update the state and the stored data

      // Change arrays to be comma-separated strings to match the expected API form
      const stateWithoutArrays = removeEmpties(
        queryStringToObject(objectToQueryString({ ...searchObj }, true))
      );

      updatePhoneSheetFilters({
        variables: {
          input: {
            phoneSheetFilters: [stateWithoutArrays],
          },
        },
      }).then((result) => {
        setDesksFilters(result?.data?.updatePhoneSheetFilters?.phoneSheetFilter);
      });

      setFilters(searchObj.filter);
      setDeskId(searchObj.deskId ?? deskId);
      setFavorite(searchObj?.favorite);
      setQuery(searchObj?.query);
    } else {
      // If the params are the same, just update the local state
      setFilters(deskFilters?.filter);
      setDeskId(deskFilters?.deskId ?? deskId);
      setFavorite(deskFilters?.favorite);
      setQuery(deskFilters?.query);

      window.history.replaceState(deskFilters, '', `?${objectToQueryString(deskFilters)}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deskId]);

  // Update URL and stored data when the query change
  const updateQuery = useCallback(
    (newQuery) => {
      setQuery(newQuery);
      const updatedParams = { ...window.history.state, query: newQuery };

      const state = removeEmpties(updatedParams);
      const queryString = objectToQueryString(state, true);
      window.history.replaceState(state, '', `?${queryString}`);
      updatePhoneSheetFilters({
        variables: {
          input: {
            phoneSheetFilters: [state],
          },
        },
      }).then((result) => {
        setDesksFilters(result?.data?.updatePhoneSheetFilters?.phoneSheetFilter);
      });
    },
    [updatePhoneSheetFilters]
  );

  // Update URL and stored data when the params change
  const updateParams = useCallback(
    (newFilters) => {
      const updatedParams = { ...window.history.state, ...newFilters };

      const queryString = objectToQueryString(removeEmpties(updatedParams), true);
      // To parse strings that belongs to an array property, we need to parse it to object again.
      const state = removeEmpties(queryStringToObject(queryString, true));

      setFilters(state.filter);
      setFavorite(state?.favorite);
      setQuery(state?.query);

      window.history.replaceState(state, '', `?${queryString}`);

      const stateWithoutArrays = removeEmpties(queryStringToObject(queryString));

      updatePhoneSheetFilters({
        variables: {
          input: {
            phoneSheetFilters: [stateWithoutArrays],
          },
        },
      }).then((result) => {
        setDesksFilters(result?.data?.updatePhoneSheetFilters?.phoneSheetFilter);
      });
    },
    [updatePhoneSheetFilters]
  );

  const changeDesk = useCallback(
    (_deskId) => {
      if (_deskId !== deskId) {
        window.history.replaceState(undefined, '', '/');
        setDeskId(_deskId);
      }
    },
    [deskId]
  );

  return {
    desk,
    search,
    deskStatuses,
    // Actions
    changeDesk,
    updateQuery,
    refreshStore,
    updateParams,
  };
}
