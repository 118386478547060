import { HttpLink, ApolloClient, InMemoryCache, from, fromPromise } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from 'apollo-link-error';

import packageJson from '../../package.json';
import { getGraphQLTokens, isGraphQLTokenExpired } from '../api/graphql';

// Configuration for version and GraphQL API URL
const { version } = packageJson;
const { REACT_APP_CHAMELEON_GRAPHQL_API_BASE_URL } = process.env;
const GRAPHQL_TOKENS_STORAGE_KEY = 'uta.graphQLTokens';

// Control variables for token refreshing
let isRefreshing = false;
let pendingRequests = [];

// Resolves all pending requests after the token refresh is complete
const resolvePendingRequests = () => {
  pendingRequests.forEach((callback) => callback());
  pendingRequests = [];
};

// Create the HttpLink for communication with the GraphQL API
const createHttpLink = () =>
  new HttpLink({
    uri: REACT_APP_CHAMELEON_GRAPHQL_API_BASE_URL,
  });

// Function to fetch tokens from local storage or fetch new ones if expired
const getTokensFromStorageOrFetchNew = async () => {
  const graphQLTokensString = localStorage.getItem(GRAPHQL_TOKENS_STORAGE_KEY);
  let graphQLTokens = null;
  let isTokenExpired = true;

  if (graphQLTokensString) {
    graphQLTokens = JSON.parse(graphQLTokensString);
    const response = await isGraphQLTokenExpired(graphQLTokens.jwtToken);
    isTokenExpired = response?.body?.isTokenExpired;
  }

  if (!graphQLTokens || isTokenExpired) {
    const response = await getGraphQLTokens();
    graphQLTokens = response?.body;
    localStorage.setItem(GRAPHQL_TOKENS_STORAGE_KEY, JSON.stringify(graphQLTokens));
  }

  return graphQLTokens;
};

// Function to prepare authentication headers for requests
const prepareAuthHeaders = async () => {
  const graphQLTokens = await getTokensFromStorageOrFetchNew();

  if (graphQLTokens) {
    return {
      interface: 'phonesheet-web',
      Authorization: `Bearer ${graphQLTokens.jwtToken}`,
      'Ocp-Apim-Subscription-Key': graphQLTokens.apimToken,
    };
  }

  return {};
};

// Authentication middleware to add headers to requests
const authMiddleware = setContext(async () => {
  const headers = await prepareAuthHeaders();

  const storedUserEmail = localStorage.getItem('uta.user_email');
  if (storedUserEmail) {
    headers.user_email = storedUserEmail;
  }

  const storedAzureId = localStorage.getItem('uta.azure_id');
  if (storedAzureId) {
    headers.azure_id = storedAzureId;
  }

  const storedOutlookPermissions = localStorage.getItem('uta.outlook_permissions');
  if (storedOutlookPermissions) {
    headers.outlook_permissions = storedOutlookPermissions;
  }

  return { headers };
});

// Function to handle network errors, specifically token refresh on 401 errors
const handleNetworkError = (networkError, operation, forward) => {
  let retryOperation;

  if (networkError.statusCode === 401) {
    if (!isRefreshing) {
      isRefreshing = true;

      // Attempt to refresh the tokens and resolve pending requests
      retryOperation = fromPromise(
        getGraphQLTokens()
          .then((response) => {
            const { body: graphQLTokens } = response || {};
            localStorage.setItem(GRAPHQL_TOKENS_STORAGE_KEY, JSON.stringify(graphQLTokens));
            resolvePendingRequests();
            return graphQLTokens;
          })
          .catch(() => {
            localStorage.removeItem(GRAPHQL_TOKENS_STORAGE_KEY);
            pendingRequests = [];
          })
          .finally(() => {
            isRefreshing = false;
          })
      ).filter(Boolean); // Filter out falsy values
    } else {
      // If already refreshing, wait for the process to complete
      retryOperation = fromPromise(new Promise((resolve) => pendingRequests.push(() => resolve())));
    }

    return retryOperation.flatMap(() => forward(operation));
  }

  return null;
};

// Error handling middleware for GraphQL and network errors
const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    console.log(`[GraphQL errors]: ${graphQLErrors}`);
  }

  if (networkError) {
    return handleNetworkError(networkError, operation, forward);
  }

  return null;
});

// Function to create the Apollo Client instance
const apolloClient = new ApolloClient({
  version,
  link: from([errorLink, authMiddleware, createHttpLink()]),
  cache: new InMemoryCache({
    addTypename: false, // Optionally disable automatic typename addition
  }),
  name: 'phonesheet-web',
});

export default apolloClient;
