import React from 'react';
import { VerificationIcon } from '@united-talent-agency/components';
import { profileUrl } from '../../../support/urls';
import EllipsisText from '../EllipsisText';
import { CALL_ROW } from '../../../support/cypressTags';

/**
 * Contact Name for the call row
 *
 * @param {Object} styles
 * @param {Object} item: full callTodo record
 * @returns {JSX.Element}
 * @constructor
 */

const CallRowName = ({ styles, item }) => {
  const showVerifiedIcon =
    item.recipient && item.recipient.verifiedByName && item.recipient.verifiedOn;

  // all call row items have a recipient (and should have a type in that object)
  // and only will be null if it is a Personal Contact
  // const entityType = item && item.recipient ? item.recipient.type : 'Personal Contact';
  const personId = ((item || {}).recipient || {})._id;
  const name = item ? item.recipientName : 'Name not found';

  return (
    <td data-cy={CALL_ROW.NAME} className={styles.name} style={{ paddingRight: '10px' }}>
      <div className={styles.person}>
        {/*fast fix hide this icon - coming back to it apparently*/}
        {/*<EntityTypeIcon id="entity-type-icon" entityType={entityType} />*/}
        <div className={styles.personName}>
          {personId ? (
            <a
              href={`${profileUrl}/${personId}`}
              className={styles.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <EllipsisText text={name} />
            </a>
          ) : (
            <EllipsisText text={name} />
          )}
        </div>
        {showVerifiedIcon && (
          <div style={{ marginLeft: '5px' }}>
            <VerificationIcon
              verifiedBy={item.recipient.verifiedByName}
              verifiedOn={item.recipient.verifiedOn}
              identifier={item.recipient._id}
              includeExplanation
            />
          </div>
        )}
      </div>
    </td>
  );
};

export default CallRowName;
