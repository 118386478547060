import _ from 'lodash';

export const getCompanyName = (callItem, showCompanyName) => {
  const groups = _.get(callItem, 'recipient.groupMembership', []);

  let primaryGroup;
  if (showCompanyName) {
    primaryGroup = _.get(callItem, 'recipient.groupMembership[0]', {});
  } else {
    try {
      primaryGroup = groups.find((group) => group.primary);
    } catch (e) {
      console.error('ONX-13 feature flags are inconsistent between projects and the API server!');
    }
  }

  const companies = _.get(callItem, 'recipient.companies', []);
  if (primaryGroup && Array.isArray(companies)) {
    const foundCompany = companies.find((company) => company._id === primaryGroup.group);
    return foundCompany?.name;
  }

  return undefined;
};

export const getCompanyId = (callItem, showCompanyName) => {
  const groups = _.get(callItem, 'recipient.groupMembership', []);
  let primaryGroup;
  if (showCompanyName) {
    primaryGroup = _.get(callItem, 'recipient.groupMembership[0]', {});
  } else {
    try {
      primaryGroup = groups.find((group) => group.primary);
    } catch (e) {
      console.error('ONX-13 feature flags are inconsistent between projects and the API server!');
    }
  }

  return primaryGroup?.group;
};

export const saveCall = (payload, patchCommunication, createCommunication) => {
  //intercept for outlook contact, they should have recipient as null to ignore any kind of contact creation
  if (payload.recipient && payload.recipient.outlook) {
    payload.recipientName = payload.recipient.name;
    payload.recipient = null;
  }

  // TODO - address mismatched data expectation: https://app.asana.com/0/1199728702258333/1201186244661870/f
  //  "satisfies the v1 update mechanism, can be removed with the v3 impl" wasn't able to remove with v3
  if (payload.deskId && payload.deskId._id) {
    payload.deskId = payload.deskId._id;
  }

  return payload._id ? patchCommunication(payload) : createCommunication(payload);
};
