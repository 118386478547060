import React, { useState } from 'react';
import { colors, Icons } from '@united-talent-agency/components';

import DeleteModal from './Modal';
import { DeleteContainer } from './styles';
import { CALL_LIST } from '../../support/cypressTags';
import { useBulkDeleteCallRecordMutation, useCallRecordsQuery } from '../../generated/graphql';
import { connect } from 'react-redux';

const Component = ({
  user,
  desk,
  selectAll,
  selectExcept = new Set(),
  filterParams,
  onExit,
  onUpdateApplied,
}) => {
  const [bulkDeleteCallRecords] = useBulkDeleteCallRecordMutation();
  const { refetch } = useCallRecordsQuery({
    skip: true,
  });

  // States
  const [bulkDeleteImpacted, setBulkDeleteImpacted] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { DeleteIcon } = Icons;
  const someSelected = selectAll || selectExcept.size > 0;

  /** Submits call_todo data to the API for bulk delete.
   *
   * @returns {void}
   **/
  const submitBulkDelete = async () => {
    setShowDeleteModal(false);

    if (!bulkDeleteImpacted) {
      return;
    }

    const selection = Array.from(selectExcept);

    try {
      if (selectAll) {
        await bulkDeleteCallRecords({
          variables: {
            input: {
              except: selection,
              deskId: desk._id,
            },
          },
        });
      } else {
        await bulkDeleteCallRecords({
          variables: {
            input: {
              ids: selection,
              deskId: desk._id,
            },
          },
        });
      }

      setBulkDeleteImpacted(0);
      onUpdateApplied && onUpdateApplied();
    } catch (error) {
      console.error(error);
      onExit && onExit();
    }
  };

  /** Handles bulk delete side effects
   *
   * @param {function} _getCommunications: Gets a list of call_todos based on query params. Dependency injected for testing.
   * @returns {void}
   **/
  const handleBulkDelete = () => {
    const totalImpacted = !selectAll
      ? Promise.resolve(selectExcept.size)
      : refetch({
          email: user?.email,
          azureId: user?.azure_id,
          where: {
            deskId: filterParams?.deskId,
            search: filterParams?.query,
            statuses: filterParams?.filter,
          },
          order: {
            statusThenOccurrence: true,
          },
          skip: 0,
          take: 1,
        })
          .then(({ data }) => data?.callRecords?.meta?.total ?? 0)
          .then((totalCount) => totalCount - selectExcept.size);

    return totalImpacted.then((impactedCount) => {
      setBulkDeleteImpacted(impactedCount);
      setShowDeleteModal(true);
    });
  };

  return (
    <>
      <DeleteContainer
        data-cy={CALL_LIST.BULK_DELETE}
        onClick={handleBulkDelete}
        someSelected={someSelected}
      >
        <div style={{ marginRight: '10px' }}>
          <DeleteIcon
            inline
            width={14}
            height={14}
            color={someSelected ? colors.utaBlack : colors.disabledGrey}
          />
        </div>
        Delete
      </DeleteContainer>
      {showDeleteModal && (
        <DeleteModal
          isOpen
          impactedCount={bulkDeleteImpacted}
          onCancel={() => {
            setBulkDeleteImpacted(0);
            onExit && onExit();
            setShowDeleteModal(false);
          }}
          onConfirm={submitBulkDelete}
        />
      )}
    </>
  );
};

const withState = connect(({ user }) => {
  return { user };
});

const BulkDeleteCommunication = withState(Component);

export default BulkDeleteCommunication;
