/**
 * Handle full refresh result
 *
 * @param {Array.<object>} results: array of fetch result per status
 * @param {Array.<string>} statuses: array of status
 * @param {function} setFetchCounts: function to set fetch counts
 * @param {function} setTotalCounts: function to set total counts
 * @param {function} setCommunications: function to set communications
 **/
export const fullRefresh = (
  results,
  statuses,
  setFetchCounts,
  setTotalCounts,
  setCommunications
) => {
  const updatedTotalCounts = {};
  const updatedFetchCounts = {};
  let updatedCommunications = [];
  results.forEach(({ data, meta }, index) => {
    if (!data || !meta) {
      return;
    }
    updatedTotalCounts[statuses[index]] = meta.total;
    updatedFetchCounts[statuses[index]] = (data ?? []).length;
    updatedCommunications = [...updatedCommunications, ...(data ?? [])];
  });
  setTotalCounts(updatedTotalCounts);
  setFetchCounts(updatedFetchCounts);
  setCommunications(updatedCommunications);

  const latestUpdate = updatedCommunications.reduce((latestUpdate, callTodo) => {
    if (!latestUpdate || callTodo.updatedAt > latestUpdate) {
      return callTodo.updatedAt;
    }
    return latestUpdate;
  }, null);
  return latestUpdate || new Date().toISOString();
};

/**
 * Handle incremental refresh result
 *
 * @param {object} result: fetch result
 * @param {object} fetchCounts: fetch count per status
 * @param {object} totalCounts: total count per status
 * @param {function} setFetchCounts: function to set fetch counts
 * @param {function} setTotalCounts: function to set total counts
 * @param {Array.<object>} communications: array of communications
 * @param {function} setCommunications: function to set communications
 **/
export const incrementalRefresh = (
  result,
  fetchCounts,
  totalCounts,
  setFetchCounts,
  setTotalCounts,
  communications,
  setCommunications
) => {
  const updatedFetchCounts = { ...fetchCounts };
  const updatedTotalCounts = { ...totalCounts };

  // filter out updated rows from previous
  const updatedCommunications = communications.filter((comm) => {
    if ((result.data ?? []).some((newCom) => newCom._id === comm._id)) {
      updatedFetchCounts[comm.status]--;
      updatedTotalCounts[comm.status]--;
      return false;
    } else {
      return true;
    }
  });

  // append new or updated rows
  (result.data ?? []).forEach((newComm) => {
    if (newComm.status in updatedFetchCounts) {
      updatedFetchCounts[newComm.status]++;
      updatedTotalCounts[newComm.status]++;
    } else {
      updatedFetchCounts[newComm.status] = 1;
      updatedTotalCounts[newComm.status] = 1;
    }
    updatedCommunications.push(newComm);
  });

  // resort by occurrence date descending
  updatedCommunications.sort((a, b) => {
    return b.occurrence_date - a.occurrence_date;
  });

  setTotalCounts(updatedTotalCounts);
  setFetchCounts(updatedFetchCounts);
  setCommunications(updatedCommunications);

  const latestUpdate = updatedCommunications.reduce((latestUpdate, callTodo) => {
    if (!latestUpdate || callTodo.updatedAt > latestUpdate) {
      return callTodo.updatedAt;
    }
    return latestUpdate;
  }, null);
  return latestUpdate || new Date().toISOString();
};

/**
 * Handle load more result
 *
 * @param {object} result: fetch result
 * @param {string} status: status to load more
 * @param {object} fetchCounts: fetch count per status
 * @param {function} setFetchCounts: function to set fetch counts
 * @param {Array.<object>} communications: array of communications
 * @param {function} setCommunications: function to set communications
 **/
export const loadMore = (
  result,
  status,
  fetchCounts,
  setFetchCounts,
  communications,
  setCommunications
) => {
  const updatedFetchCounts = { ...fetchCounts };
  const updatedCommunications = [...communications, ...(result.data ?? [])];

  // resort by occurrence date descending
  updatedCommunications.sort((a, b) => {
    return b.occurrence_date - a.occurrence_date;
  });

  updatedFetchCounts[status] += (result.data ?? []).length;
  setFetchCounts(updatedFetchCounts);
  setCommunications(updatedCommunications);
};
