import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { helpers, styled } from 'react-free-style';
import OutsideClickHandler from 'react-outside-click-handler';
import { elements, icons } from '@united-talent-agency/julius-frontend-components';
import { colors } from '@united-talent-agency/components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import InlineEditableContact from './InlineEditableContact';
import Notes from './Notes';
import CallHistoryTable from './CallHistoryTable';
import { useToasts } from 'react-toast-notifications';
import { CALL_ROW } from '../../support/cypressTags';
import RowCheckbox from './RowCheckbox';
import Status from './Status';
import Starred from './Starred';
import CallRowName from './CallRowName';
import CompanyName from './CompanyName';
import CallDate from './CallDate';
import StaticNotes from './StaticNotes';
import Actions from './Actions';
import { CallTableRow } from './styles';

const Component = ({
  item,
  hideBulkEdits = false,
  recipient,
  selected,
  statuses,
  onSelectedChange,
  onSave,
  onDeleteItem,
  onEditItem,
  styles,
  deskContacts,
}) => {
  const { addToast } = useToasts();
  const { notesModal: showNotesModal = true, companyName: showCompanyName = false } = useFlags();
  // States
  const [history, setHistory] = useState(false);
  const [call, setCall] = useState(item);
  const [updatedAt, setUpdatedAt] = useState(item.updatedAt);

  useEffect(() => {
    const newUpdate = new Date(item.updatedAt).getTime();
    if (!updatedAt || updatedAt < newUpdate) {
      setUpdatedAt(newUpdate);
    }

    setCall(item);
  }, [item, updatedAt]);

  const updateCall = useCallback(
    (values) => {
      // we must create a copy to make the call editable (currently read-only).
      const callCopy = JSON.parse(JSON.stringify(call));
      const newCall = { ...callCopy, ...values };
      delete newCall?.recipientType;
      delete newCall?.extension;
      delete newCall?.contact?.description;
      delete newCall?.contact?.private;
      newCall.recipientId = newCall?.recipient;
      delete newCall?.recipient;
      delete newCall?.person;
      newCall.notes = newCall.notes?.map((note) => ({
        ...note,
        note: note.note,
        createdBy: note.createdBy,
      }));

      return onSave(newCall)
        .then(setCall)
        .catch((e) => {
          setCall(newCall);
          addToast(e.message, { appearance: 'error', autoDismiss: true });
          return { error: 'failed update' };
        });
    },
    [addToast, call, onSave]
  );

  return (
    <CallTableRow
      id={`call-row-${call._id}`}
      data-occurrence-date={call.occurrence_date}
      data-updated-at={call.updatedAt}
      data-cy={CALL_ROW.CALL_ROW}
      onDoubleClick={() => (showNotesModal ? null : onEditItem(call))}
      selected={selected}
    >
      {!hideBulkEdits && <RowCheckbox onChange={onSelectedChange} selected={selected} />}
      <Status callTodo={call} statuses={statuses} onSave={updateCall} styles={styles} />
      <Starred favorite={call.favorite} onSave={updateCall} />
      <CallRowName styles={styles} item={call} />

      {showCompanyName && <CompanyName item={call} styles={styles} />}
      <InlineEditableContact
        id="inline-editable-contact"
        deskAddressBookEntryContacts={deskContacts}
        callTodo={call}
        recipient={recipient}
        onSave={updateCall}
        canEdit={call.recipient?.canEdit}
      />
      <CallDate occurrenceDate={call.occurrence_date} onSave={updateCall} styles={styles} />
      {showNotesModal ? (
        <Notes notes={call?.notes} onSave={updateCall} />
      ) : (
        <StaticNotes notes={call.description} id={call._id} styles={styles} />
      )}
      <Actions
        id={call._id}
        showHistory={() => setHistory(true)}
        onEdit={() => onEditItem(call)}
        onDelete={() => onDeleteItem(call)}
      />
      {history && (
        <td className={styles.historyCell}>
          <OutsideClickHandler onOutsideClick={() => setHistory(false)}>
            <CallHistoryTable callId={call._id} />
          </OutsideClickHandler>
        </td>
      )}
    </CallTableRow>
  );
};

const withStyles = styled({
  name: {
    minWidth: '116px',
  },
  person: {
    display: 'flex',
    cursor: 'default',
    alignItems: 'center',
    maxWidth: '100%',
  },
  personName: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
  },
  company: {
    ['@container call-list-container (max-width: 1150px)']: {
      display: 'none',
    },
  },
  contact: {
    cursor: 'pointer',
    textOverflow: 'ellipsis',
    fontWeight: 300,
  },
  date: {
    whiteSpace: 'nowrap',
    '&:hover': {
      cursor: 'pointer',
    },
    maxWidth: '100%',
  },
  note: {
    display: 'flex',
    alignItems: 'center',
  },
  noteContent: {
    width: '350px',
    float: 'left',
    overflowWrap: 'break-word',
    paddingLeft: '5px',
  },
  noteBorder: {
    display: 'inline-block',
    width: 1,
    height: 20,
    backgroundColor: colors.whiteSmoke,
  },
  actionButtons: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
  },
  button: helpers.merge(elements.reset, elements.actionable, {
    padding: 10,
    lineHeight: 0,
  }),
  editIcon: icons.pencil,
  declineIcon: icons.cross,
  deleteIcon: icons.trashcan,
  status: {
    paddingLeft: 7,
    paddingRight: 7,
    width: 55,
  },
  faOutline: {
    textShadow: '0px 0px 1px black',
  },
  iconSize: {
    fontSize: 14,
  },
  link: {
    color: '#2187B9',
    '&:hover': {
      color: '#2187B9',
    },
    maxWidth: '100%',
  },
  maxWidth: {
    maxWidth: '100%',
  },
  historyCell: {
    position: 'relative',
  },
});

const withState = connect(({ user }) => ({
  user,
}));

const CallRow = withState(withStyles(Component));

export default CallRow;
