import React, { useState, useEffect, useCallback, useMemo } from 'react';
import styled from 'styled-components/macro';
import {
  colors,
  Icons,
  Checkbox,
  Button,
  Tooltip,
  ToggleSwitch,
} from '@united-talent-agency/components';
import StatusList from '../StatusList';
import { HEADER } from '../../support/cypressTags';
import { notify } from 'react-notify-toast';
import {
  useDeleteStatusMutation,
  useUpdateDeskMutation,
  useUpdateStatusMutation,
} from '../../generated/graphql';

export const updateStatusOrder = ({ oldIndex, newIndex }, statuses, onChange) => {
  let _statuses = statuses.slice();
  //switch the status item's places in the array
  const movedStatusItem = _statuses.splice(oldIndex, 1);
  _statuses.splice(newIndex, 0, ...movedStatusItem);

  //set the order field to be the index
  for (let i = 0; i < _statuses.length; i++) {
    _statuses[i].order = i;
  }

  onChange(_statuses);
  return _statuses;
};

export const SettingsModalContent = ({ desk, deskStatuses, onCancel, onSave }) => {
  // Mutation
  const [updateDesk] = useUpdateDeskMutation();
  const [updateStatuses] = useUpdateStatusMutation();
  const [deleteStatuses] = useDeleteStatusMutation();

  // States
  const [statuses, setStatuses] = useState();
  const [settingsErrorState, setSettingsErrorState] = useState(false);
  const [hideFutureCallsChecked, setHideFutureCallsChecked] = useState(
    !!desk?.settings?.hideFutureCalls
  );
  const [collapseCallFormChecked, setCollapseCallFormChecked] = useState(
    !!desk?.settings?.collapseCallForm
  );
  const [hideBulkEditsChecked, setHideBulkEditsChecked] = useState(!!desk?.settings?.hideBulkEdits);

  useEffect(() => {
    // Creates a shallow copy of each object in the array to prevent modifying the original array.
    const deskStatusesCopy = deskStatuses.map((item) => ({ ...item }));
    setStatuses(deskStatusesCopy);
  }, [deskStatuses]);

  useEffect(() => {
    setHideBulkEditsChecked(!!desk?.settings?.hideBulkEdits);
  }, [desk?.settings?.hideBulkEdits]);

  useEffect(() => {
    setHideFutureCallsChecked(!!desk?.settings?.hideFutureCalls);
  }, [desk?.settings?.hideFutureCalls]);

  useEffect(() => {
    setCollapseCallFormChecked(!!desk?.settings?.collapseCallForm);
  }, [desk?.settings?.collapseCallForm]);

  /** Handles closing the modal
   *
   */
  const handleOnClickClose = () => {
    onCancel();
  };

  /** Removes a status from the status list
   *
   * @param {Number} index: the index of the status to remove
   **/
  const removeStatus = (index) => {
    let _statuses = statuses.slice();

    _statuses.splice(index, 1);
    setStatuses(_statuses);
  };

  /** Updates a status from the status list
   *
   * @param {("color"|"name"|"status"|"type")} fieldType: the field to update on the status object
   * @param {String} updatedValue: the new value
   * @param {Number} index: the index of the status to update
   **/
  const updateStatusField = (fieldType, updatedValue, index) => {
    let _statuses = [...statuses];
    _statuses[index][fieldType] = updatedValue;
    setStatuses(_statuses);
  };

  const settingsFieldsAreValid = useMemo(() => {
    const _statuses = [].concat.apply(
      [],
      statuses?.length ? statuses?.map((status) => Object.values(status)) : ['']
    );

    return !_statuses.includes('');
  }, [statuses]);

  /**
   * Handles saving changes to the status list
   * Saves changes, then updates the search params in the querystring
   **/
  const handleSave = async () => {
    if (settingsFieldsAreValid) {
      try {
        const statusesToCreateOrUpdate = statuses.map((status) => {
          const statusCopy = { ...status };
          delete statusCopy?.hasCallRecord;
          delete statusCopy?.count;

          return statusCopy;
        });

        const idsOfExistingStatuses = statuses.map((status) => status?._id).filter(Boolean);
        const statusesToDelete = deskStatuses
          .filter((status) => !idsOfExistingStatuses.includes(status._id))
          .map((status) => {
            const statusCopy = { ...status };
            delete statusCopy?.hasCallRecord;
            delete statusCopy?.count;

            return { ...statusCopy, remove: true };
          });

        if (statusesToDelete?.length) {
          await deleteStatuses({
            variables: {
              statuses: statusesToDelete,
            },
          });
        }

        await updateStatuses({
          variables: {
            input: statusesToCreateOrUpdate,
          },
        });
        await updateDesk({
          variables: {
            id: desk?._id,
            input: {
              settings: {
                hideFutureCalls: hideFutureCallsChecked,
                hideBulkEdits: hideBulkEditsChecked,
                collapseCallForm: collapseCallFormChecked,
              },
            },
          },
        });
        setSettingsErrorState(false);
        onSave && onSave();
        notify.show('Settings saved', 'success');
      } catch (error) {
        console.error(error);
        notify.show(error.message, 'error');
      }
    } else {
      setSettingsErrorState(true);
    }
  };

  const handleBulkToggleSwitch = () => {
    setHideBulkEditsChecked((previousValue) => !previousValue);
  };

  const addStatus = useCallback(() => {
    setStatuses((oldState) => [
      ...oldState,
      {
        order: oldState.length + 1,
        color: '',
        status: '',
        name: '',
        type: '',
        count: 0,
        deskId: (desk || {})._id || '',
      },
    ]);
  }, [desk]);

  return (
    <div>
      <CloseButtonContainer data-cy={HEADER.SETTINGS.MODAL_CLOSE}>
        <Icons.XCloseIcon
          inline
          onClick={handleOnClickClose}
          height={23}
          width={23}
          style={{ cursor: 'pointer', marginRight: -7 }}
        />
      </CloseButtonContainer>

      <ModalTitle>Settings</ModalTitle>
      <div style={{ display: 'flex', margin: '20px 40px', justifyContent: 'space-between' }}>
        <span>Bulk Changes</span>
        <ToggleSwitch
          initialToggleState={!hideBulkEditsChecked}
          onChange={handleBulkToggleSwitch}
        />
      </div>
      <div
        data-cy={HEADER.SETTINGS.MODAL_ALWAYS_KEEP_CALL_FORM_OPENED}
        style={{ display: 'flex', margin: '20px 40px', justifyContent: 'space-between' }}
      >
        <span>Always show Add Call form</span>
        <ToggleSwitch
          initialToggleState={!collapseCallFormChecked}
          onChange={() => setCollapseCallFormChecked((oldState) => !oldState)}
        />
      </div>
      <hr style={{ margin: '0px 40px' }} />
      <div data-cy={HEADER.SETTINGS.MODAL_HIDE_FUTURE_CALLS} style={{ margin: '20px 47px' }}>
        <Checkbox
          title="Hide future calls"
          checked={hideFutureCallsChecked}
          onChange={() => setHideFutureCallsChecked((oldState) => !oldState)}
          clearedBackground
        />
      </div>
      <p style={{ fontSize: 18, margin: '0 47px -5px', fontWeight: 300 }}>Status</p>
      <p style={{ color: 'grey', marginLeft: 47, fontWeight: 300, fontSize: 12 }}>
        (Drag and Drop to reorder)
      </p>

      <StatusList
        useDragHandle
        distance={5}
        desk={desk}
        statuses={statuses}
        errorState={settingsErrorState}
        removeStatus={removeStatus}
        updateStatusField={updateStatusField}
        onSortEnd={(indexUpdate) => updateStatusOrder(indexUpdate, statuses, setStatuses)}
      />

      <AddStatusContainer>
        {(statuses || []).length < 6 ? (
          <AddStatusText type="button" tabIndex={0} onClick={addStatus}>
            <span style={{ fontSize: 18 }}>+</span>
            <span style={{ position: 'relative', margin: 'auto 4px' }}>Add Status</span>
          </AddStatusText>
        ) : (
          <Tooltip text="You can only have 6 statuses">
            <DisabledAddStatusText type="button" tabIndex={0}>
              <span style={{ fontSize: 18 }}>+</span>
              <span style={{ position: 'relative', margin: 'auto 4px' }}>Add Status</span>
            </DisabledAddStatusText>
          </Tooltip>
        )}
      </AddStatusContainer>

      <SaveButtonContainer data-cy={HEADER.SETTINGS.MODAL_SAVE}>
        <SaveButton tabIndex={0} small onClick={handleSave} text="Save" />
      </SaveButtonContainer>
    </div>
  );
};

const AddStatusContainer = styled.div({
  margin: '5px 48px',
});

const AddStatusText = styled.button({
  display: 'flex',
  width: 'max-content',
  backgroundColor: 'transparent',
  outline: 'none',
  cursor: 'pointer',
  fontFamily: 'aktiv-grotesk, sans-serif',
  fontSize: 12,
  color: colors.linkBlue,

  border: '1px solid transparent',
  ':focus': {
    border: '1px solid rgb(33, 135, 185)',
  },
});

const DisabledAddStatusText = styled(AddStatusText)({
  color: colors.disabledGrey,
  cursor: 'default',
});

const CloseButtonContainer = styled.div({
  position: 'absolute',
  right: 15,
});

const SaveButtonContainer = styled.div({
  margin: '0 38px 12px 0',
  justifyContent: 'flex-end',
  display: 'flex',
  position: 'relative',
});

const SaveButton = styled(Button)({
  ':focus': {
    border: '1px solid rgb(33, 135, 185)',
  },
});

const ModalTitle = styled.div({
  display: 'flex',
  justifyContent: 'center',
  fontSize: 24,
  fontWeight: 300,
});

export default SettingsModalContent;
